import ActionLink from 'components/atoms/ActionLink';
import React from 'react';

const CTAColors = [
	{ bg: 'bg-default' },
	{ bg: 'bg-green-lighter' },
	{ bg: 'bg-green-light' },
	{ bg: 'bg-green-dark', text: 'color-white' },
];

type ListItem = {
	id: string;
	fields: {
		Link: {
			value: {
				href: string;
				linktype: string;
				target: string;
				text: string;
			};
		};
		svg: {
			value: string;
		};
	};
};

type StickyCTAProps = {
	fields: {
		list: Array<ListItem>;
	};
};

const StickyCTA = (props: StickyCTAProps): JSX.Element => {
	if ( props == undefined 
		) {
			return <div>Data source is not defined</div>;
		}
	
		const { fields } = props;
	
		if ( fields == undefined || fields.list == undefined ) 
		{
			return <div>Data source is not defined</div>;
		}
	
	return (
		<div className="CTA-container">
			{fields?.list?.map(({ id, ...data }, index) => {
				return (
					<ActionLink
						key={id}
						link={data?.fields?.Link?.value?.href}
						iconClassName={data?.fields?.svg?.value}
						label={data?.fields?.Link?.value?.text}
						{...{ id, icon: data?.fields?.svg?.value }}
						additionalClasses={`${CTAColors[index].bg} ${CTAColors[index].text}`}
					/>
				);
			})}
		</div>
	);
};

export default StickyCTA;
