import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Image from 'components/atoms/Image';

type ImageORVideoContentProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		description: {
			value: string;
		};
		videourl: {
			value: string;
		};
	};
};

const ImageORVideoContent = (props: ImageORVideoContentProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div
			className={`jss-inner-container innovation-media ${
				props.fields.videourl && props.fields.videourl.value ? 'video' : ''
			}`}
		>
			<div className="media-container">
				{props.fields.image.value && props.fields.image.value.src !== undefined ? (
					<Image
						imageUrl={props.fields.image.value.src}
						alt={
							(props.fields.image.value.alt as string) != ''
								? (props.fields.image.value.alt as string)
								: 'Image'
						}
					/>
				) : null}

				{props.fields.videourl && props.fields.videourl.value ? (
					<iframe
						src={`${props.fields.videourl.value}?enablejsapi=1`}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					></iframe>
				) : null}
			</div>
			{props.fields.description.value ? (
				<div className="caption">{props.fields.description.value}</div>
			) : null}
		</div>
	);
};

export default ImageORVideoContent;
