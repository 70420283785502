import { StyleguideComponentProps } from 'lib/component-props';
import { useEffect, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
type BreadcrumbProps = StyleguideComponentProps & {
	fields: {
		url: string;
		id: string;
		items: Array<{
			url: string;
			id: string;
			displayName: string;
			fields: {
				breadcrumbtitle: { value: string };
			};
		}>;
	};
};

type WindowSize = {
	width: number;
	height: number;
};

const getWindowDimensions = (): WindowSize => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};
type CustomSitecoreContextType = {
	sitecoreContext: { itemPath: string };
};
const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
	const [windowSize, setWindowSize] = useState<WindowSize>({ width: 0, height: 0 });
	const lastItem = props.fields?.items.length - 1;
	const lastLink = props.fields?.items[lastItem];
	let breadcrumbStack = props.fields?.items;

	const relativePathStartsWith = (path: unknown, value: string): boolean => {
		return typeof path === 'string' && path.startsWith(`${value}`);
	};

	const context: CustomSitecoreContextType = useSitecoreContext({ updatable: false });

	const urlPath = context?.sitecoreContext ? context?.sitecoreContext?.itemPath : '';

	if (
		relativePathStartsWith(urlPath, '/care') ||
		relativePathStartsWith(urlPath, '/r2') ||
		relativePathStartsWith(urlPath, '/gic')
	) {
		breadcrumbStack = props.fields?.items.slice(1);
	}

	useEffect(() => {
		setWindowSize(getWindowDimensions());
	}, []);

	if (!props.fields) {
		return <p>No datasource set</p>;
	}

	return (
		<div className="breadcrumb">
			<div className="breadcrumb-inner">
				<ul>
					{breadcrumbStack.length > 2 && windowSize.width < 600 ? (
						<>
							<li>/ ...</li>
							<li key={breadcrumbStack[breadcrumbStack.length - 2].id}>
								{lastLink.id != breadcrumbStack[breadcrumbStack.length - 2].id ? (
									<a href={breadcrumbStack[breadcrumbStack.length - 2].url}>
										{breadcrumbStack[breadcrumbStack.length - 2].fields.breadcrumbtitle.value}
									</a>
								) : (
									breadcrumbStack[breadcrumbStack.length - 2].fields.breadcrumbtitle.value
								)}
							</li>
						</>
					) : (
						<>
							{breadcrumbStack.length > 0}
							{breadcrumbStack.map((link) => {
								return (
									<li key={link.id}>
										{lastLink.id != link.id ? (
											<a href={link.url}>
												{link.fields.breadcrumbtitle.value} &nbsp; {'/'}
											</a>
										) : (
											link.fields.breadcrumbtitle.value
										)}
									</li>
								);
							})}
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default Breadcrumb;
