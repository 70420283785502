import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type LocationCollection = {
	id: string;
	url: string;
	name: string;
	fields: {
		Street1: Field<string>;
		FeaturedStreet2: Field<string>;
		City: Field<string>;
		LocationId: Field<string>;
		State: Field<string>;
		Zip: Field<string>;
		LocationImage: ImageField;
		FeaturedLocationLink: {
			value: string;
		};
		LocationName: Field<string>;
	};
};
type FeaturedLocationsProps = {
	fields: {
		Title: Field<string>;
		Locations: Array<LocationCollection>;
		link: LinkField;
	};
};

const FeaturedLocations = (props: FeaturedLocationsProps): JSX.Element => {
	const { fields } = props;
	if (fields == undefined) {
		return <div>Data source is not defined</div>;
	}

	return (
		<div className="featured-list locations">
			<Text tag="h3" className="featured-list-title" field={fields?.Title} />
			<div className="list-inner">
				{fields?.Locations &&
					fields?.Locations?.length > 0 &&
					fields?.Locations.map(({ fields, id, name }) => (
						<a
							href={`https://www2.osfhealthcare.org/locations/${String(name)
								?.toLowerCase()
								?.split(' ')
								?.join('-')}-${String(fields?.LocationId?.value)
								?.replace('LOC', '')
								?.replace(/^0+/, '')}`}
							key={id}
							className="featured-item"
						>
							<div className="item-image">
								{fields?.LocationImage?.value?.src ? (
									<Image field={fields?.LocationImage} />
								) : (
									<img
										alt={`image-${id}`}
										src="https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-location-transparent"
									/>
								)}
							</div>
							<div className="content-container">
								<div
									className="address"
									itemProp="hospitalAffiliation"
									itemType="http://schema.org/Hospital"
								>
									<Text
										tag="h4"
										className="item-name"
										itemProp="hospitalAffiliation"
										field={fields?.LocationName}
									/>
									<h5
										className="item-subtitle"
										itemProp="address"
										itemScope
										itemType="http://schema.org/PostalAddress"
									>
										{fields?.Street1 && <Text field={fields?.Street1} />} <br />
										{fields?.City && <Text field={fields?.City} />},{' '}
										{fields?.State && <Text field={fields?.State} />}{' '}
										{fields?.Zip && <Text field={fields?.Zip} />}
									</h5>
								</div>
							</div>
						</a>
					))}
			</div>
			{fields.link && fields.link?.value?.href ? (
				<Link className="featured-list-view-all" field={fields?.link} />
			) : null}
		</div>
	);
};

export default FeaturedLocations;
