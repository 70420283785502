import { Text, RichText, Field, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type HeroParagraphProps = StyleguideComponentProps & {
	fields: {
		sectionTitle: Field<string>;
		title: Field<string>;
		description?: Field<string>;
		link: LinkField;
	};
};

const HeroParagraph = (props: HeroParagraphProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="text-container jss-inner-container hero-paragraph">
			{props.fields.sectionTitle && (
				<Text tag="h4" className="section-title content-block" field={props.fields.sectionTitle} />
			)}
			{props.fields.title && (
				<Text tag="h1" className="hero-content-title content-block" field={props.fields.title} />
			)}
			{props.fields.description && (
				<RichText className="hero-content-paragraph" field={props.fields.description} />
			)}
			{props.fields.link && (
				<Link className="hero-content-paragraph color-green jss-link" field={props.fields.link} />
			)}
		</div>
	);
};

export default HeroParagraph;
