import React, { ReactNode, KeyboardEvent } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import GetBreakpoint from '../utils/getBreakPoint';
import Icons from '../../components/atoms/Icons';

type ModelProps = {
	ariaLabel?: string;
	children?: ReactNode;
	customClass?: string;
	hideDefaultCloseBtn?: boolean;
	modalOnly?: boolean; // boolean for modal with no associated open button (ie alerts or auto-open modals)
	modalTitle?: string;
	openBtnIcon?: string;
	openBtnLabel?: string;
	role?: string;
	openOnLoad?: boolean;
	onCloseModal?: () => void;
	onOpenModal?: () => void;
	portalId: string;
	shouldCloseOnOverlayClick?: boolean;
};

type ModelState = {
	isOpen: boolean;
	isVisible: boolean;
};

class Modal extends React.Component<ModelProps, ModelState> {
	modal: HTMLDivElement | null | undefined;
	constructor(props: ModelProps) {
		super(props);
	}

	static defaultProps = {
		portalId: 'modal-root',
		openBtnLabel: 'Open modal',
		shouldCloseOnOverlayClick: true,
	};

	state: ModelState = {
		isOpen: false,
		isVisible: false,
	};

	open = (): void => {
		this.setState(
			() => ({ isVisible: true }),
			() => {
				document.body.classList.add('body-fixed');
				document.body.classList.add('custom-modal-open');

				this.setState(() => ({ isOpen: true }));
			}
		);

		if (this.props.onOpenModal) {
			this.props.onOpenModal();
		}
	};

	close = (): void => {
		document.body.classList.remove('custom-modal-open');
		this.setState(
			() => ({ isOpen: false }),
			() => {
				document.body.classList.remove('body-fixed');
				setTimeout(() => {
					this.setState(() => ({ isVisible: false }));
				}, 300);

				if (this.props.onCloseModal) {
					this.props.onCloseModal();
				}
			}
		);
	};

	closeOverlayOnClick = (e: React.MouseEvent<HTMLDivElement>): void => {
		const target = e.target as HTMLDivElement;
		if (
			this.props.shouldCloseOnOverlayClick &&
			(GetBreakpoint() === 'lg' || GetBreakpoint() === 'xl' || GetBreakpoint() === 'xxl')
		) {
			target.classList.contains('modal-container') && this.close();
		}
	};

	keyDown = (e: KeyboardEvent): void => {
		if (e.keyCode === 27 || e.charCode === 27) {
			this.close();
		}
	};

	componentDidMount = (): void => {
		if (this.props.openOnLoad) {
			this.open();
		}
	};

	componentDidUpdate = (): void => {
		if (this.modal) {
			this.modal.querySelector('.custom-modal-close-btn')?.addEventListener('click', this.close);
		}
	};

	render(): JSX.Element {
		const { customClass, hideDefaultCloseBtn, modalOnly, modalTitle, openBtnIcon, openBtnLabel } =
			this.props;

		return (
			<>
				{!modalOnly && (
					<button className="modal-open-btn" onClick={this.open}>
						{openBtnLabel ? openBtnLabel : null}
						{openBtnIcon ? (
							<svg className={`icon icon-${openBtnIcon}`}>
								<use xlinkHref={`#${openBtnIcon}`}></use>
							</svg>
						) : null}
					</button>
				)}
				{this.state.isVisible
					? ReactDOM.createPortal(
							<FocusTrap
								focusTrapOptions={{
									escapeDeactivates: false,
								}}
							>
								<div
									className={
										'modal ' +
										(customClass ? customClass : '') +
										(this.state.isVisible ? ' visible' : '') +
										(this.state.isOpen ? ' open' : '')
									}
									tabIndex={-1}
									aria-modal="true"
									aria-label={this.props.ariaLabel}
									role={this.props.role}
									onClick={this.closeOverlayOnClick}
									onKeyDown={this.keyDown}
									ref={(c) => {
										this.modal = c;
									}}
								>
									<div className="modal-container">
										<div className="modal-inner-container">
											<div className="modal-body">
												<div className="modal-inner">
													{!hideDefaultCloseBtn && (
														<button
															className="modal-close-btn"
															type="button"
															onClick={this.close}
															aria-label="Close Modal"
														>
															<span className="icon icon-close">
																<Icons id="close" />
															</span>
														</button>
													)}
													{modalTitle ? <h4 className="modal-title">{modalTitle}</h4> : null}
													{this.props.children}
												</div>
											</div>
										</div>
									</div>
								</div>
							</FocusTrap>,
							document.getElementById(this.props.portalId) as HTMLElement
					  )
					: null}
			</>
		);
	}
}

export default Modal;
