import { Field, ImageField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { useEffect, useRef } from 'react';
import { ItemValue } from 'components/types/ListItem';

type ExpandableContentPromoProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		heading: Field<string>;
		content: Field<string>;
		items: Array<ItemValue>;
	};
};

const ExpandableContentPromo = (props: ExpandableContentPromoProps): JSX.Element => {
	if ( props == undefined || !props || props.fields == undefined || !props.fields
		) {
			return <div>Data source is not defined</div>;
		}
	
		
	const { items } = props.fields;

	useEffect(() => {
		const expandables = document.getElementsByClassName(
			'expandable-content'
		) as HTMLCollectionOf<HTMLElement>;
		Array.from(expandables).forEach((item) => {
			const content = item?.getElementsByClassName('content') as HTMLCollectionOf<HTMLElement>;
			const div = content[0].getElementsByTagName('div');
			if (div[1].innerText.length < 200) {
				const icon = item?.getElementsByTagName('svg')[0];
				icon && icon.remove();
			}
		});
	}, []);
	useEffect(() => {
		if (window.innerWidth < 768) {
			Array.from(document.getElementsByClassName('expandable-content')).forEach((item) => {
				const content = item?.getElementsByClassName('content')[0] as HTMLElement;
				const imageWrapper = content?.getElementsByClassName('img-wrapper')[0] as HTMLElement;
				const image = imageWrapper?.getElementsByTagName('img')[0] as HTMLImageElement;

				if (image) {
					const imageHeight = image.clientHeight;
					content.style.maxHeight = 250 + imageHeight + 'px';
				}
			});
		}
	}, []);
	const contentRef = useRef<HTMLDivElement>(null);

	const expandContent = (id: string) => {
		const itemEl = document.getElementById(id);
		const content = itemEl?.getElementsByClassName('content')[0] as HTMLElement;
		const imageWrapper = content?.getElementsByClassName('img-wrapper')[0] as HTMLElement;
		const image = imageWrapper?.getElementsByTagName('img')[0] as HTMLImageElement;
		const icon = itemEl?.getElementsByTagName('svg')[0] as unknown as HTMLElement;

		if (content.classList.contains('expanded')) {
			let collapsedHeight = '250px';
			if (window.innerWidth < 768 && image) {
				console.log(`entrou`);
				const imageHeight = image.clientHeight;
				collapsedHeight = 250 + imageHeight + 'px';
			}

			content.style.maxHeight = collapsedHeight;
			icon && (icon.style.transform = 'unset');
			content.classList.remove('expanded');
		} else {
			content.style.maxHeight = content.scrollHeight + 'px'; // Set max-height to actual height
			icon && (icon.style.transform = 'rotate(180deg)');
			content.classList.add('expanded');
		}
	};

	return (
		<div className="expandable-parent">
			{items &&
				items.map((item) => (
					<div key={item.id}>
						<div className="expandable-content jss-base-container" key={item.id} id={item.id}>
							<div className="expandable-content-wrapper">
								<div className="content" ref={contentRef}>
									<div className="img-wrapper">
										<img
											src={item.fields.image.value?.src}
											alt={
												(item.fields.image.value?.alt as string) != ''
													? (item.fields.image.value?.alt as string)
													: 'Image'
											}
										/>
									</div>
									<RichText tag="span" field={item.fields.heading} />
									<RichText field={item.fields.content} />
								</div>
							</div>

							<div className="icon-wrapper" onClick={() => expandContent(item.id)}>
								<svg
									className="collapsible"
									xmlns="http://www.w3.org/2000/svg"
									width="14.829"
									height="8.828"
									viewBox="0 0 14.829 8.828"
								>
									<g
										id="Icon_Arrow_Right"
										data-name="Icon Arrow Right"
										transform="translate(13.415 1.414) rotate(90)"
									>
										<path
											id="Path_747"
											data-name="Path 747"
											d="M4918.513-354.946l6,6-6,6"
											transform="translate(-4918.513 354.946)"
											fill="none"
											stroke="#6cb50d"
										/>
									</g>
								</svg>
							</div>
						</div>
						{items[items.length - 1] && items[items.length - 1].id !== item.id && (
							<div className="expandable-divider"></div>
						)}
					</div>
				))}
		</div>
	);
};

export default ExpandableContentPromo;
