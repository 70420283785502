import { Field, ImageField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type OneUpProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		description: Field<string>;
		image: ImageField;
		children: Array<ItemValue>;
	};
};

const OneUp = (props: OneUpProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="jss-inner-container jss-oneup-container">
			<ContentBlock title={props.fields.title} description={props.fields.description} />
			{props.fields.children &&
				props.fields.children.map((item) => (
					<div className="one-up" key={item.id}>
						<div className="one-up-img">
							<img
								src={item.fields.image.value?.src}
								alt={
									(item.fields.image.value?.alt as string) != ''
										? (item.fields.image.value?.alt as string)
										: 'Image'
								}
							/>
						</div>
						<div className="one-up-content">
							{item.fields.heading && <Text tag="h1" field={item.fields.heading} />}
							{item.fields.content && <RichText field={item.fields.content} />}
						</div>
					</div>
				))}
			{!props.fields.children && <p>No datasource set</p>}
		</div>
	);
};

export default OneUp;
