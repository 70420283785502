import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import Icons from 'components/atoms/Icons';

type SocialMediaLinksProps = {
	fields: {
		SocialMediaLinks: Array<SocialMediaLinksContent>;
		Title: Field<string>;
	};
};

type SocialMediaLinksContent = {
	fields: {
		Link: {
			value: {
				href: string;
				url: string;
				text: string;
			};
		};
		svg: {
			value: string;
		};
	};
};

const SocialMediaLinks = (props: SocialMediaLinksProps): JSX.Element => {
	if ( props == undefined 
		) {
			return <div>Data source is not defined</div>;
		}
	
	const { fields } = props;
	return (
		<div className="social-media-links">
			<Text className="social-description" tag="p" field={fields?.Title} />
			<div className="social-links">
				{fields?.SocialMediaLinks?.map(({ fields }, index) => (
					<a
						key={index}
						className="social-link"
						target="_blank"
						rel="noreferrer"
						href={fields?.Link?.value?.href || '#'}
					>
						<span className={`icon icon-${fields?.svg?.value}`}>
							<Icons id={fields?.svg?.value} />
						</span>
					</a>
				))}
			</div>
		</div>
	);
};

export default SocialMediaLinks;
