import React from 'react';
import { Link, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { classNames } from 'assets/utils/helper';
import Icons from 'components/atoms/Icons';

type SubNavProviderProps = {
	id: string;
	data?: any;
	closeSubNav?: () => void;
	closeMobileNav?: () => void;
	mobileNavOpen: boolean;
};

const SubNavProvider = (props: SubNavProviderProps): JSX.Element => {
	const closeMenu = (e: any) => {
		e.target?.closest('.sub-nav-menu').classList.add('closed');
		e.target?.blur();
	};

	return (
		<div className="sub-nav-menu">
			<div className="sub-nav-mobile-header">
				<button
					className="mobile-nav-back-btn"
					aria-label="Close Services Navigation"
					onClick={props.closeSubNav}
				>
					<span className="icon icon-chevron-right">
						<Icons id="icon-chevron-right" />
					</span>
				</button>
				<h4 className="sub-nav-title">{props.id || ''}</h4>
				<button
					className="mobile-nav-close-btn"
					aria-label="Close Navigation"
					onClick={props.closeMobileNav}
				>
					<span className="icon icon-close">
						<Icons id="close" />
					</span>
				</button>
			</div>
			<div className="sub-nav-content">
				{props.data.map((fields: any) => (
					<div key={fields?.id} className="sub-nav-section">
						<Text tag="h5" className="sub-nav-heading" field={fields?.fields?.heading || ''} />
						<ul
							className={classNames(
								fields.fields.listitemtype?.name === 'ListItem'
									? 'sub-nav-link-list'
									: fields.fields.listitemtype?.name === 'ButtonLink'
										? 'sub-nav-btn-list'
										: 'sub-nav-link-list'
							)}
						>
							{fields?.fields?.lists &&
								fields?.fields?.lists?.map((links: any) => (
									<li key={links.id}>
										{props.mobileNavOpen ? (
											<Link
												className={classNames(
													fields.fields.listitemtype.name === 'ListItem'
														? 'sub-nav-link'
														: fields.fields.listitemtype.name === 'ButtonLink'
															? 'sub-nav-link-btn'
															: 'sub-nav-link'
												)}
												aria-label="Link Learn More"
												data-target=""
												rel="noreferrer"
												field={links?.fields?.generalLink || ''}
												onClick={props.closeMobileNav}
											/>
										) : (
											<Link
												className={classNames(
													fields.fields.listitemtype.name === 'ListItem'
														? 'sub-nav-link'
														: fields.fields.listitemtype.name === 'ButtonLink'
															? 'sub-nav-link-btn'
															: 'sub-nav-link'
												)}
												aria-label="Link Learn More"
												data-target=""
												rel="noreferrer"
												field={links?.fields?.generalLink || ''}
												onClick={(e) => {
													closeMenu(e);
												}}
											/>
										)}
									</li>
								))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
};

export default SubNavProvider;
