import React from 'react';
import {
	Field,
	Image,
	ImageField,
	Link,
	LinkField,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';

type FiveUpCardProps = {
	active: boolean;
	link: LinkField;
	description: Field<string>;
	heading: Field<string>;
	image: ImageField;
	index: number;
	// eslint-disable-next-line no-unused-vars
	toggleActiveCard: (index: number) => void;
};

const FiveUpCard = ({
	active,
	link,
	description,
	heading,
	image,
	index,
	toggleActiveCard,
}: FiveUpCardProps): JSX.Element => {
	return (
		<div
			className={`five-up-card ${active ? 'active' : ''}`}
			onClick={() => toggleActiveCard(index)}
		>
			<div className="five-up-img">
				<div className="control">
					<div className="control-previous">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="8.828"
							height="14.829"
							viewBox="0 0 8.828 14.829"
						>
							<g
								id="Icon_Arrow_Right"
								data-name="Icon Arrow Right"
								transform="translate(1.414 1.414)"
							>
								<path
									id="Path_747"
									data-name="Path 747"
									d="M4918.513-354.946l6,6-6,6"
									transform="translate(-4918.513 354.946)"
									fill="none"
									stroke="white"
									strokeLinecap="round"
									strokeWidth="2"
								></path>
							</g>
						</svg>
					</div>
					<div className="control-next">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="8.828"
							height="14.829"
							viewBox="0 0 8.828 14.829"
						>
							<g
								id="Icon_Arrow_Right"
								data-name="Icon Arrow Right"
								transform="translate(1.414 1.414)"
							>
								<path
									id="Path_747"
									data-name="Path 747"
									d="M4918.513-354.946l6,6-6,6"
									transform="translate(-4918.513 354.946)"
									fill="none"
									stroke="white"
									strokeLinecap="round"
									strokeWidth="2"
								></path>
							</g>
						</svg>
					</div>
				</div>
				{image && <Image field={image} alt="OSF Innovation" />}
			</div>
			<div className="five-up-content">
				{heading && <RichText className="five-up-card-heading" tag="h4" field={heading} />}
				<span className="five-up-card-cta">Read more</span>
				{description && <RichText className="five-up-card-description" field={description} />}
				{link && (
					<Link
						className="five-up-card-link"
						aria-label="Link Learn More"
						data-target=""
						rel="noreferrer"
						field={link}
						onClick={(e) => e.stopPropagation()}
					/>
				)}
			</div>
		</div>
	);
};

export default FiveUpCard;
