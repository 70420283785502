import React, { useEffect, useState } from 'react';
import { Field, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';

type LocationSelecterItem = {
	id: string;
	fields: {
		About: Field<string>;
		AcceptingNewPatients: Field<boolean>;
		AdaComplianceInfo: Field<string>;
		BookOnlineOverrideUrl: Field<string>;
		City: Field<string>;
		Country: Field<string>;
		HomePageUrl: Field<string>;
		HoursOfOperation: Field<string>;
		InsuranceAccepted: Field<string>;
		'Is Permanently Closed': Field<string>;
		KyruusOneId: Field<string>;
		Latitude: Field<number>;
		Longitude: Field<number>;
		Zip: Field<string>;
		Suite: Field<string>;
		Street1: Field<string>;
		State: Field<string>;
		Phone: Field<string>;
		LocationType: Field<string>;
		LocationName: Field<string>;
		LocationId: Field<string>;
		LocationImage: ImageField;
	};
};

type HospitalLocationProps = {
	fields: {
		locationSelector: Array<LocationSelecterItem>;
	};
};

const HospitalLocation = (props: HospitalLocationProps): JSX.Element => {
	const { fields } = props;

	//Directions and Google Maps Embed API Process
	// State to store the user's location
	const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(
		null
	);

	const [distance, setDistance] = useState<string | null>(null);


	useEffect(() => {
		// Check if Geolocation is available in the browser
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					// User's location is available
					setUserLocation({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					});
				},
				(error) => {
					console.error('Error getting user location:', error);
				}
			);
		} else {
			console.error('Geolocation is not available in this browser');
		}
	}, []);


	useEffect(() => {
		if (userLocation) {
			// Geocode the destination address to get its coordinates
			const service = new google.maps.DistanceMatrixService();
			const userLatLng = new google.maps.LatLng(
				userLocation.latitude,
				userLocation.longitude
			);
			// Calculate the distance
			service.getDistanceMatrix(
				{
					origins: [userLatLng],
					destinations: [address],
					unitSystem: google.maps.UnitSystem.IMPERIAL,
					travelMode: google.maps.TravelMode.DRIVING
				},
				(response, status) => {
					if (status === 'OK' && response!.rows.length > 0) {
						const newDistance = response!.rows[0].elements[0].distance.text;
						setDistance(newDistance);
					} else {
						console.error('Error fetching distance:', status);
						setDistance('Distance not available');
					}
				}
			);
		};
	}
		, [userLocation]);

	// Check if the first location item exists and has the required fields
	const firstLocation = fields?.locationSelector?.[0];
	let address = '';

	if (firstLocation) {
		const { Street1, City, State, Zip } = firstLocation.fields;
		address = `${Street1?.value}, ${City?.value}, ${State?.value} ${Zip?.value}`;
	}

	const googleApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

	// Construct the Google Maps Embed API URL
	const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${address}`;

	return (
		<>
			{fields?.locationSelector?.map(({ id, fields }) => (
				<div key={id} className="hospital-location">
					<div className="location-details">
						<Text tag="h4" className="header" field={fields?.LocationName} />
						<Text
							tag="p"
							className="address"
							field={{
								value: `${fields?.Street1?.value} ${fields?.Suite?.value} \n${fields?.City.value}, ${fields?.State?.value} ${fields?.Zip?.value}`,
							}}
						/>
						<div className="contact">
							<Text tag="p" className="m-0" field={{ value: `${fields?.Phone?.value}` }} />
						</div>
						<div className="location-section">
							<span className="icon icon-location" aria-hidden="true">
								<Icons id="location" />
							</span>
							{distance ? (
								<>
									<span>{distance}</span>&nbsp; | &nbsp; {' '}
								</>
							) : null}
							<a
								href={`${userLocation
									? `https://www.google.com/maps/dir/${userLocation.latitude},${userLocation.longitude}/${fields?.Street1?.value}, ${fields?.City?.value}, ${fields?.State?.value} ${fields?.Zip?.value}`
									: `https://www.google.com/maps/dir//${fields?.Street1?.value}, ${fields?.City?.value}, ${fields?.State?.value} ${fields?.Zip?.value}`
									}`}
							>Get Directions</a>
						</div>
					</div>
					<div className="location">
						<iframe
							src={embedUrl}
							width="200"
							height="150"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							tabIndex={0}
						/>
					</div>
					{/* <div className="location">
						<p className="m-0">Parking</p>
						<div className="location-section">
							<svg className="icon icon-map-parking" aria-hidden="true">
								<use xlinkHref="#map-parking"></use>
							</svg>
							<span>X.xx Miles</span> | <span>Paid</span> | <a href="">Directions</a>
						</div>
						<div className="location-section">
							<svg className="icon icon-map-parking" aria-hidden="true">
								<use xlinkHref="#map-parking"></use>
							</svg>
							<span>X.xx Miles</span> | <span>Paid</span> | <a href="">Directions</a>
						</div>
					</div> */}
					{/*<div className="location">
						<div className="map-location" onClick={handlePrintLocation}>
							<p className="m-0 location-map">Location Map PDF</p>
							<span className=" m-0 icon icon-download"></span>
						</div>
					</div>*/}
				</div>
			))}
		</>
	);
};

export default HospitalLocation;
