import React from 'react';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';

type ArticleSummaryProps = {
	stacked: boolean;
	imgSrc?: string;
	imgAlt?: string;
	date?: string;
	location?: Field<string>;
	title: string;
	summary?: string;
	citationText?: string;
	citationHref?: string;
	ctaText?: string;
	ctaHref?: string;
};

const returnURL = (url: string): string => {
	const urlVal = new URL(url);
	const pathAfterDomain = urlVal.pathname;

	return pathAfterDomain;
};

const ArticleSummary = (props: ArticleSummaryProps): JSX.Element => {
	const {
		stacked,
		imgSrc,
		imgAlt,
		date,
		location,
		title,
		summary,
		citationText,
		citationHref,
		ctaText,
		ctaHref,
	} = props;

	return (
		<div className={`article-summary ${stacked ? 'stacked' : ''}`}>
			<div className="summary-img">{imgSrc ? <img src={imgSrc} alt={imgAlt} /> : <div></div>}</div>
			<div className="summary-content">
				<div className="summary-inner">
					<div className="date-and-location">
						{date && <Text className="summary-date" tag="span" field={{ value: date }} />}
						{location && <Text className="summary-location" tag="span" field={location} />}
					</div>
					{title && <Text className="summary-title" tag="h4" field={{ value: title }} />}
					{summary && (
						<RichText tag="div" className="summary-description" field={{ value: summary }} />
					)}
					{citationText && (
						<div className="citation-container">
							<span>View story at: </span>
							<a className="summary-citation" href={citationHref}>
								{citationText}
								<span className="icon icon-external">
									<Icons id="external" />
								</span>
							</a>
						</div>
					)}
				</div>
				{ctaText && ctaHref && (
					<a className="summary-cta" href={returnURL(ctaHref)}>
						{ctaText}
						<span className="icon icon-arrow-go">
							<Icons id="arrow-go" />
						</span>
					</a>
				)}
			</div>
		</div>
	);
};

export default ArticleSummary;
