import React from 'react';
import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CopyrightFooter from '../organisms/CopyrightFooter';

type GeneralLinkItem = {
	id: string;
	fields: {
		generalLink: LinkField;
	};
};

type DescriptionLinksItem = {
	id: string;
	name: string;
	fields: {
		LinkList: Array<GeneralLinkItem>;
	};
};

type NestedLinksItem = {
	id: string;
	fields: {
		generalLink: {
			value: {
				href: string;
				text: string;
				target: string;
				linktype: string;
			};
		};
	};
};

type GetCareFooterProps = {
	fields: {
		heading: Field<string>;
		Logo: ImageField;
		FooterDescription: Field<string>;
		DescriptionLinks: Array<DescriptionLinksItem>;
		nestedLinks: Array<NestedLinksItem>;
		disclaimer: Field<string>;
	};
};

const GetCareFooter = (props: GetCareFooterProps): JSX.Element => {
	const { fields } = props;

	if ( props == undefined 
		) {
			return <div>Data source is not defined</div>;
		}
		
	return (
		<>
			<footer className="www2-footer" id="site-footer" data-module="Www2Footer">
				<div className="inner-wrap">
					<div className="footer-col" id="footer-info">
						<Image id="footer-logo" field={fields?.Logo || ''} />
						<Text tag="p" className="footer-hd" field={fields?.heading} />
						<p>
							<Text tag="em" field={fields?.FooterDescription} />
						</p>
					</div>
					{fields &&
						fields?.DescriptionLinks?.map(({ id, name, fields }, index) => {
							return (
								<nav
									aria-labelledby={`footer-col${index + 1}-header`}
									className="footer-col"
									key={id}
								>
									<p className="footer-hd" id="footer-col1-header">
										<a className="track-nav" href="#">
											{name || ''}
										</a>
									</p>
									<ul>
										{fields &&
											fields?.LinkList?.map(({ id, fields }) => (
												<li key={id}>
													<Link
														className="track-nav"
														aria-label="Link Learn More"
														rel="noreferrer"
														field={fields?.generalLink}
														onClick={(e) => e?.stopPropagation()}
													/>
												</li>
											))}
									</ul>
								</nav>
							);
						})}
				</div>
			</footer>
			<CopyrightFooter
				{...{
					copyright: fields?.disclaimer,
					nestedLinks: fields?.nestedLinks || [],
				}}
			/>
		</>
	);
};

export default GetCareFooter;
