import React from 'react';
import ActionButton from '../r2/ActionButton';

type ActionButtonItem = {
	id: string;
	fields: {
		Link: {
			value: {
				text: string;
				href: string;
				target: string;
			};
		};
		svg: {
			value: string;
		};
	};
};

type ActionButtonGroupProps = {
	fields: {
		list: Array<ActionButtonItem>;
	};
};

const ActionButtonGroup = (props: ActionButtonGroupProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="action-btn-group">
			{fields?.list?.map(({ id, fields }) => {
				return (
					<ActionButton
						key={id}
						isLinkExternal={fields?.Link?.value?.target || ''}
						link={fields?.Link?.value?.href || '#'}
						label={fields?.Link?.value?.text || ''}
						additionalClasses={fields?.svg?.value || ''}
						icon={fields?.svg?.value || ''}
					/>
				);
			})}
		</div>
	);
};

export default ActionButtonGroup;
