import Head from 'next/head';
import React, { useEffect } from 'react';
import { getPublicUrl } from 'lib/util';
import {
	Placeholder,
	VisitorIdentification,
	useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideSitecoreContextValue } from 'lib/component-props';
import InnovationHeader from 'lib/InnovationHeader';
import GetCareHeader from 'lib/GetCareHeader';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-manifest';

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = getPublicUrl();

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.

type LayoutProps = {
	context: StyleguideSitecoreContextValue;
};

new InnovationHeader(true).init();
new GetCareHeader(true).init();

const Layout = ({ context }: LayoutProps): JSX.Element => {
	const { updateSitecoreContext } = useSitecoreContext({ updatable: true });
	// Update Sitecore Context if layoutData has changed (i.e. on client-side route change).
	// Note the context object type here matches the initial value in [[...path]].tsx.
	useEffect(() => {
		updateSitecoreContext && updateSitecoreContext(context);
	}, [context]);

	const { route } = context;

	const pageTitle =
		route.fields &&
			route.fields['Page Title']?.value &&
			route.fields['Page Title']?.value !== '$name'
			? `${route.fields['Page Title'].value} | OSF HealthCare`
			: `${route.name[0].toUpperCase() + route.name.slice(1)} | OSF HealthCare`;

	const OGImage = route.fields?.OpenGraphImageUrl?.value;

	const relativePathStartsWith = (path: unknown, value: string): boolean => {
		return typeof path === 'string' && path.startsWith(`${value}`);
	};
	//load ivy chatbot on all billing pages within patients visitors
	function loadIvyScript() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://bot.ivy.ai/bot/script/category/Vv1K8zqBOmpxaLMKY7lQ6XY20RgkjWw5';
		script.async = true;
		script.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
		document.head.append(script);
	}

	useEffect(() => {
		if (window && window.location.pathname.startsWith('/patients-visitors/billing-insurance')) {
			loadIvyScript();
		}
	}, []);

	return (
		<>
			<Head>
				<script
					dangerouslySetInnerHTML={{
						__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KHGKWQN');`,
					}}
				></script>
				<link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet"></link>
				<meta
					name="google-site-verification"
					content="5uojjV9CUMlSlDis96XduGZM6U1uR7z14kj0e2Cb5ks"
				/>
				<title>{pageTitle}</title>
				<link rel="icon" href={`${publicUrl}/favicon.ico`} />

				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, minimum-scale=1"
				/>

				<meta
					name="description"
					content={
						route.fields &&
						(route.fields.MetaDescription?.value !== '$name'
							? (route.fields.MetaDescription?.value as string)
							: '')
					}
				/>
				<meta
					name="keywords"
					content={
						route.fields &&
						(route.fields.MetaKeywords?.value !== '$name'
							? (route.fields.MetaKeywords?.value as string)
							: '')
					}
				/>

				<meta
					name="robots"
					content={route.fields && (route.fields.RobotsMetaTag?.value as string)}
				/>
				<meta
					name="twitter:card"
					content={route.fields && (route.fields.TwitterCardType?.value as string)}
				/>
				<meta
					name="twitter:site"
					content={route.fields && (route.fields.OpenGraphSiteName?.value as string)}
				/>
				<meta
					name="twitter:title"
					content={route.fields && (route.fields.OpenGraphTitle?.value as string)}
				/>
				<meta
					name="twitter:description"
					content={route.fields && (route.fields.OpenGraphDescription?.value as string)}
				/>
				<meta
					name="twitter:image"
					content={route.fields && (OGImage as unknown as ImageFieldValue).src}
				/>

				<meta
					property="og:type"
					content={route.fields && (route.fields.OpenGraphType?.value as string)}
				/>
				<meta
					property="og:title"
					content={route.fields && (route.fields.OpenGraphTitle?.value as string)}
				/>
				<meta
					property="og:description"
					content={route.fields && (route.fields.OpenGraphDescription?.value as string)}
				/>
				<meta
					property="og:url"
					content={route.fields && (route.fields.OpenGraphSiteName?.value as string)}
				/>
				<meta
					property="og:image"
					content={route.fields && (OGImage as unknown as ImageFieldValue).src}
				/>
			</Head>

			{/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
			<VisitorIdentification />

			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-KHGKWQN"
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				></iframe>
			</noscript>

			{/* Google map */}
			<script
				type="text/javascript"
				src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjtlB7VsJ0SmtNzMGXyw0_EmSXQvd6qBk&libraries=places&sensor=false"
			></script>

			{/* root placeholder for the app, which we add components to using route data */}
			{(() => {
				if (relativePathStartsWith(context.itemPath, '/care')) {
					return (
						<div className="jss-container">
							<Placeholder name="gcn-header" rendering={route} />
							<div className="get-care-container">
								<div className="content-container">
									<Placeholder name="gcn-page-title" rendering={route} />
									<div className="row">
										<div className="col-12 col-sm-12 col-md-6 col-lg-6">
											<Placeholder name="gcn-left-column" rendering={route} />
										</div>
										<div className="col-12 col-sm-12 col-md-6 col-lg-6">
											<Placeholder name="gcn-right-column" rendering={route} />
										</div>
										<div className="col col-12">
											<Placeholder name="gcn-full-width" rendering={route} />
										</div>
									</div>
								</div>
							</div>
							<Placeholder name="gcn-footer" rendering={route} />
						</div>
					);
				} else if (relativePathStartsWith(context.itemPath, '/gic')) {
					return (
						<div className="jss-container">
							<Placeholder name="jss-header" rendering={route} />
							<Placeholder name="jss-main" rendering={route} />
							<Placeholder name="jss-footer" rendering={route} />
						</div>
					);
				} else {
					return (
						<div className="jss-container">
							<div id="r2-header-plac">
								<Placeholder name="r2-header" rendering={route} />
							</div>
							<div id="r2-main-plac">
								<Placeholder name="r2-main" rendering={route} />
							</div>
							<div id="r2-footer-plac">
								<Placeholder name="r2-footer" rendering={route} />
							</div>
						</div>
					);
				}
			})()}
		</>
	);
};

export default Layout;
