import React from 'react';
import { ImageField, Field, LinkField, Link, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Image from 'components/atoms/Image';
import { ItemValue } from 'components/types/ListItem';

type ContentListingProps = StyleguideComponentProps & {
	fields: {
		itemHeading: Field<string>;
		description: Field<string>;
		cta: LinkField;
		image: ImageField;
		renderingVariant: ItemValue;
	};
};

const ContentListing = (props: ContentListingProps): JSX.Element => {

	if (
		props.fields == undefined ||
		!props.fields.itemHeading ||
		!props.fields.description ||
		!props.fields.renderingVariant
	) {
		return <div>Data source is not defined</div>;
	}
	
	const { itemHeading, description, cta, image, renderingVariant } = props?.fields;

	if (!image || !itemHeading || !description ) {
		return <div></div>;
	}

	if (itemHeading && renderingVariant?.name === 'With CTA Button') {
		return (
			<div className="content-listing jss-inner-container">
				<div className="listing-content">
					<h4 className="listing-heading">{itemHeading?.value}</h4>
					<RichText tag="div" className="listing-description" field={description} />
					<Link className="listing-cta innovation-button" field={cta} />
				</div>
			</div>
		);
	}
	if (itemHeading && renderingVariant?.name === 'With CTA Link') {
		return (
			<div className="content-listing jss-inner-container">
				<div className="listing-content">
					<h4 className="listing-heading">{itemHeading?.value}</h4>
					<RichText tag="div" className="listing-description" field={description} />
					<Link className="listing-cta" field={cta} />
				</div>
			</div>
		);
	}
	if (itemHeading && renderingVariant?.name === 'With Image and CTA Button') {
		return (
			<div className="content-listing jss-inner-container">
				<div className="listing-img">
					<Image
						imageUrl={image?.value?.src as string}
						alt={(image?.value?.alt as string) != '' ? (image?.value?.alt as string) : 'Image'}
					/>
				</div>
				<div className="listing-content jss-inner-container">
					<h4 className="listing-heading">{itemHeading?.value}</h4>
					<RichText tag="div" className="listing-description" field={description} />
					<Link className="listing-cta innovation-button" field={cta} />
				</div>
			</div>
		);
	}
	if (itemHeading && renderingVariant?.name === 'With Image Right and CTA Button') {
		return (
			<div className="content-listing img-right jss-inner-container">
				<div className="listing-img">
					<Image
						imageUrl={image?.value?.src as string}
						alt={(image?.value?.alt as string) != '' ? (image?.value?.alt as string) : 'Image'}
					/>
				</div>
				<div className="listing-content">
					<h4 className="listing-heading">{itemHeading?.value}</h4>
					<RichText tag="div" className="listing-description" field={description} />
					<Link className="listing-cta innovation-button" field={cta} />
				</div>
			</div>
		);
	} else {
		return (
			<div className="content-listing jss-inner-container">
				<div className="listing-content">
					<h4 className="listing-heading">{itemHeading?.value}</h4>
					<RichText tag="div" className="listing-description" field={description} />
				</div>
			</div>
		);
	}
};

export default ContentListing;
