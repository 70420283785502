import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import React from 'react';

type TwoColumnProps = StyleguideComponentProps & {};

const TwoColumn = (props: TwoColumnProps): JSX.Element => (
	<div className="jss-two-column">
		<div className="left-column">
			<Placeholder name="jss-left-column" rendering={props.rendering} />
		</div>
		<div className="right-column">
			<Placeholder name="jss-right-column" rendering={props.rendering} />
		</div>
	</div>
);

export default TwoColumn;
