import { Field, ImageField, Item, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { FeaturedProviders as FeaturedProvidersComponent } from '@aha-health/pdc-featured-providers';

type SettingItemProps = {
	name: string;
	displayName: string;
	fields: {
		'Setting Value': {
			value: string;
		};
	};
};

type ImageProps = {
	alt: string;
	src: string;
};

type LocationProps = {
	Latitude: {
		value: number;
	};
	Longitude: {
		value: number;
	};
};

type ProviderProps = {
	fields: {
		AppointmentAvailability: {
			value: string;
		};
		AcceptingNewPatients: {
			value: boolean;
		};
		ExternalId: {
			value: string;
		};
		FirstName: {
			value: string;
		};
		FullName: {
			value: string;
		};
		ProviderImage: {
			value: ImageProps;
		};
		LastName: {
			value: string;
		};
		Locations: {
			value: LocationProps[];
		};
		MiddleInitial: {
			value: string;
		};
		PreferredName: {
			value: string;
		};
		SortField: {
			value: string;
		};
	};
};

type FeaturedProvidersProps = StyleguideComponentProps & {
	fields: {
		apiDomain: Field<string>;
		colorScheme: SettingItemProps;
		customApi: Field<boolean>;
		defaultZip: Field<string>;
		displayMilesAway: Field<boolean>;
		fontFamily: SettingItemProps;
		forceDefaultZip: Field<boolean>;
		hasZipInput: Field<boolean>;
		helpButton: Field<string>;
		helpDescription: Field<string>;
		helpPhone: Field<string>;
		horizontal: Field<boolean>;
		noBorderRadius: Field<boolean>;
		preferredProviderLabel: Field<string>;
		providerCount: Field<number>;
		providerCtaText: Field<string>;
		providerProfileBaseUrl: Field<string>;
		providers: Field<ProviderProps>[];
		imageBackground: ImageField;
		imageFallbackFemale: ImageField;
		imageFallbackMale: ImageField;
		searchTerm: Item;
		showProviderCta: Field<boolean>;
		helpHeading: Field<string>;
		title: Field<string>;
		viewAllUrl: LinkField;
	};
};

const FeaturedProviders = ({ fields }: FeaturedProvidersProps): JSX.Element => {
	if (fields == undefined) {
		return <div>Datasource missing for Featured Providers (aha! PDC)</div>;
	}
	const {
		apiDomain,
		colorScheme,
		customApi,
		defaultZip,
		displayMilesAway,
		fontFamily,
		forceDefaultZip,
		hasZipInput,
		helpButton,
		helpDescription,
		helpHeading,
		helpPhone,
		horizontal,
		noBorderRadius,
		preferredProviderLabel,
		providerCount,
		providerCtaText,
		providerProfileBaseUrl,
		providers,
		imageBackground,
		imageFallbackFemale,
		imageFallbackMale,
		searchTerm,
		showProviderCta,
		title,
		viewAllUrl,
	} = fields;

	return (
		<FeaturedProvidersComponent
			apiDomain={apiDomain.value}
			colorScheme={colorScheme.fields['Setting Value']?.value}
			customApi={customApi.value}
			defaultZip={defaultZip.value}
			displayMilesAway={displayMilesAway.value}
			fontFamily={fontFamily.fields['Setting Value']?.value}
			forceDefaultZip={forceDefaultZip.value}
			hasZipInput={hasZipInput.value}
			helpDescription={helpDescription.value}
			helpHeading={helpHeading.value}
			helpPhone={helpPhone.value}
			horizontal={horizontal.value}
			noBorderRadius={noBorderRadius.value}
			preferredProviderLabel={preferredProviderLabel.value}
			providerCount={providerCount.value}
			providerCtaText={providerCtaText.value}
			providerProfileBaseUrl={providerProfileBaseUrl.value}
			providers={providers}
			imageBackground={imageBackground.value?.src}
			imageFallbackFemale={imageFallbackFemale.value?.src}
			imageFallbackMale={imageFallbackMale.value?.src}
			searchTerm={searchTerm?.displayName}
			showProviderCta={showProviderCta.value}
			title={title.value}
			viewAllHref={viewAllUrl.value.href}
			viewAllText={helpButton.value || viewAllUrl.value.text}
			viewAllTarget={viewAllUrl.value.target}
		/>
	);
};

export default FeaturedProviders;
