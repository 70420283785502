import {
	Text,
	Field,
	LinkField,
	ImageField,
	Link,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import ActionButton from './ActionButton';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type ThreeUpProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		description: Field<string>;
		link: LinkField;
		viewalllink: LinkField;
		children: Array<ItemValue>;
	};
};

const ThreeUp = (props: ThreeUpProps): JSX.Element => {
	if (props.fields == undefined) {
		return <div>Datasource not set </div>;
	}
	return (
		<>
			<div className="jss-three-up-headline jss-inner-container">
				<ContentBlock title={props.fields.title} description={props.fields.description} />
			</div>
			<div
				className={
					props.fields.children.length < 3 && props.fields.children.length > 0 ? 'jss-two-up' : `jss-three-up jss-inner-container`
				}
			>
				{props.fields.children.length > 0 &&
					props.fields.children.map((item) => (
						<div key={item.id} className="additional-cards">
							{item.fields.image.value?.src !== undefined && (
								<div className="">
									<img
										src={item.fields.image.value?.src}
										alt={
											(item.fields.image.value?.alt as string) != ''
												? (item.fields.image.value?.alt as string)
												: 'Image'
										}
									/>
								</div>
							)}
							{(item.fields.heading.value !== '' || item.fields.content.value !== '') && (
								<div className="content">
									{item.fields.heading.value !== '' && (
										<Text tag="h5" className="header" field={item.fields.heading} />
									)}
									{item.fields.content.value !== '' && (
										<RichText tag="div" className="description" field={item.fields.content} />
									)}
								</div>
							)}
							{item.fields.link.value.href !== '' && (
								<div className="jss-action-button">
									<ActionButton
										label={item.fields.link.value.text}
										link={item.fields.link.value.href}
										isLinkExternal={item.fields.link.value.target}
										icon="arrow-left"
										rotateIcon={180}
									/>
								</div>
							)}
							{item.fields.viewalllink.value.href !== '' && (
								<div>
									<Link
										className="text-plus color-green jss-link"
										field={item.fields.viewalllink}
									/>
								</div>
							)}
						</div>
					))}
			</div>
		</>
	);
};
export default ThreeUp;
