import React from 'react';
import { ImageField, Field, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Image from '../atoms/Image';
import { ItemValue } from '../types/ListItem';
import Video from '../atoms/Video';

type HeroComponentProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		videoURL: Field<string>;
		description: Field<string>;
		renderingVariant: ItemValue;
		primaryCTA: LinkField;
		secondaryCTA: LinkField;
		dynamicHero: Field<boolean>;
	};
};

const HeroComponent = (props: HeroComponentProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	if (props.fields.renderingVariant && props.fields.renderingVariant?.name === 'Hero CTA') {
		const dynamicClass = props.fields.dynamicHero?.value ? ' dynamic-hero' : '';
		return (
			<div className={`hero${dynamicClass}`}>
				{props.fields.image && (
					<Image
						imageUrl={props.fields.image?.value?.src as string}
						alt={
							(props.fields.image.value?.alt as string) != ''
								? (props.fields.image.value?.alt as string)
								: 'Image'
						}
						aditionalClass="hero-img"
					/>
				)}
				<div className="hero-cta">
					<p>{props.fields.description && props.fields.description?.value}</p>
					<div className="btn-wrapper">
						{props.fields.primaryCTA && props.fields.primaryCTA?.value.href !== '' && (
							<div>
								<Link className="innovation-button hero-primary" field={props.fields.primaryCTA} />
							</div>
						)}

						{props.fields.secondaryCTA && props.fields.secondaryCTA?.value.href !== '' && (
							<div>
								<Link
									className="innovation-button hero-secondary"
									field={props.fields.secondaryCTA}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
	else if (props.fields.renderingVariant && props.fields.renderingVariant?.name === 'Hero Video') {
		const dynamicClass = props.fields.dynamicHero?.value ? ' dynamic-hero' : '';
		return (
			<div className={`hero${dynamicClass}`}>
				{props.fields.videoURL && (
					<Video
						videoUrl={props.fields.videoURL?.value as string}
						aditionalClass="hero-vid"
					/>
				)}
			</div>
		);
	}
	else {
		const dynamicClass = props.fields.dynamicHero?.value ? ' dynamic-hero' : '';
		return (
			<div className={`hero${dynamicClass}`}>
				{props.fields.image && (
					<Image
						imageUrl={props.fields.image?.value?.src as string}
						alt={
							(props.fields.image.value?.alt as string) != ''
								? (props.fields.image.value?.alt as string)
								: 'Image'
						}
						aditionalClass="hero-img"

					/>
				)}
			</div>
		);
	}
};

export default HeroComponent;
