import { ImageField, Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type ColumnContainer = {
	id: string;
	fields: {
		generalLink: LinkField;
	};
};

type SocialLink = {
	id: string;
	fields: {
		heading: { value: string };
		Link?: LinkField;
		svg?: {
			value: string;
		};
		items?: Array<ColumnContainer>;
	};
};

type MenuSubItem = {
	id: string;
	fields: {
		Link: {
			value: {
				href: string;
				text: string;
				linktype: string;
				url: string;
				anchor: string;
				target: string;
			};
		};
		svg?: {
			value: string;
		};
		image?: ImageField;
		className?: { value: string };
		items?: Array<SocialLink>;
	};
};

type MenuItem = {
	id: string;
	fields: {
		className: {
			value: string;
		};
		items: Array<MenuSubItem>;
	};
};
type SocialLinks = {
	id: string;
	fields: {
		Link: LinkField;
		svg: {
			value: string;
		};
	};
};
type GetCareHeaderProps = {
	fields: {
		Link: {
			value: {
				href: string;
				text: string;
				linktype: string;
				url: string;
				anchor: string;
				target: string;
			};
		};
		svg: {
			value: string;
		};
		logo: {
			value: string;
		};
		socialLinks: Array<SocialLinks>;
		items: Array<MenuItem>;
		logolink: LinkField;
	};
};

const GetCareHeader = (props: GetCareHeaderProps): JSX.Element => {
	if ( props == undefined 
		) {
			return <div>Data source is not defined</div>;
		}
		
	const { fields } = props;

	return (
		<header className="www2-header" id="site-header" data-module="GetCareHeader">
			<div id="main-nav-row">
				<nav id="main-nav" className="nav container" role="navigation" aria-label="Primary">
					<div id="search-container">
						<div id="search-toggle">
							<Link field={{ value: { ...fields?.Link?.value, target: '' } }}>
								<span>Search</span>
							</Link>
						</div>
					</div>
					<a className="toggle nav-toggle" href="#" id="main-nav-toggle">
						<span>Toggle</span> OSF HealthCare
					</a>
					<div className="menu-container">
						<ul>
							{fields &&
								fields?.items?.length &&
								fields?.items[0]?.fields?.items?.map(({ fields, id }) => (
									<li key={id} className={fields?.className?.value || ''} id={fields?.svg?.value}>
										<a href={fields?.Link?.value?.url || '#'}>{fields?.Link?.value?.text}</a>
										<ul>
											{fields?.items &&
												fields?.items?.length &&
												fields?.items?.map(({ fields, id }) => (
													<li key={id} className="columns">
														<a className="no-href">{fields?.heading?.value || ''}</a>
														<ul>
															{fields?.items &&
																fields?.items?.length &&
																fields?.items?.map(({ fields, id }) => (
																	<li key={id}>
																		<Link field={fields?.generalLink} />
																	</li>
																))}
														</ul>
													</li>
												))}
										</ul>
									</li>
								))}
						</ul>
					</div>
				</nav>
			</div>
			<div id="mast" className="container">
				<a
					href={fields?.logolink?.value?.href}
					title="OSF HealthCare"
					target={fields?.logolink?.value?.target}
				>
					<h1 id="logo"></h1>
				</a>
				<div id="phone-social">
					<div className="phone-number"></div>
					<nav id="social" aria-label="Social">
						{fields?.socialLinks &&
							fields?.socialLinks?.length &&
							fields?.socialLinks?.map(({ fields, id }) => (
								<a
									key={id}
									className={fields?.svg?.value}
									href={fields?.Link?.value?.href}
									target={fields?.Link?.value?.target}
									rel="noopener noferrer noreferrer"
								>
									{fields?.Link?.value?.text}
								</a>
							))}
					</nav>
				</div>
				<span id="specialty-logo"></span>
			</div>
		</header>
	);
};

export default GetCareHeader;
