import { Text, Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type ContentBlockProps = {
	title: Field<string>;
	description?: Field<string>;
};

const ContentBlock = ({ title, description }: ContentBlockProps): JSX.Element => (
	<div className="text-container content-block">
		{title ? (
			<Text tag="h2" className="h2" field={title} />
		) : null}
		{description ? (
			<RichText tag="div" className="paragraph-intro color-light-black" field={description} />
		) : null}
	</div>
);

export default ContentBlock;
