import React from 'react';
import ChevronUp from '../../assets/images/icons/chevron-up.svg';
import ChevronDown from '../../assets/images/icons/chevron-down.svg';
import ChevronColoured from '../../assets/images/icons/chevron-down-colored.svg';
import FacebookSquare from '../../assets/images/icons/facebook-square.svg';
import InstagramSquare from '../../assets/images/icons/instagram-square.svg';
import YoutubeSquare from '../../assets/images/icons/youtube-square.svg';
import LinkedInSquare from '../../assets/images/icons/linkedin-square.svg';
import TwitterSquare from '../../assets/images/icons/twitter-square.svg';
import Critical from '../../assets/images/icons/critical.svg';
import Info from '../../assets/images/icons/info-circle.svg';
import Close from '../../assets/images/icons/close.svg';
import Warning from '../../assets/images/icons/warning.svg';
import FacebookRound from '../../assets/images/icons/facebook.svg';
import LinkedInRound from '../../assets/images/icons/linkedin.svg';
import TwitterRound from '../../assets/images/icons/twitter.svg';
import Search from '../../assets/images/icons/search.svg';
import SearchGreen from '../../assets/images/icons/search-green.svg';
import Location from '../../assets/images/icons/location.svg';
import DefaultProfile from '../../assets/images/icons/profile-md.svg';
import Menu from '../../assets/images/icons/menu.svg';
import ArrowGo from '../../assets/images/icons/arrow-go.svg';
import ArrowGoDefault from '../../assets/images/icons/arrow-go-md.svg';
import ArrowLeft from '../../assets/images/icons/arrow-left.svg';
import Profile from '../../assets/images/icons/profile.svg';
import ImageGrid from '../../assets/images/icons/image_grid.svg';
import ChevronLeftIcon from '../../assets/images/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/images/icons/chevron-right.svg';
import Share from '../../assets/images/icons/share.svg';
import Print from '../../assets/images/icons/print.svg';
import Calender from '../../assets/images/icons/calendar.svg';
import ExpandFS from '../../assets/images/icons/expand-fs.svg';
import PlayFilled from '../../assets/images/icons/play-filled.svg';
import ChevronIcon from '../../assets/images/icons/chevron-next.svg';
import MapParking from '../../assets/images/icons/map-parking.svg';

type IconsProps = {
	id: string;
	className?: string;
	isDefault?: boolean;
	isColored?: boolean;
};

const Icons: React.FC<IconsProps> = ({ id, isDefault, isColored, ...props }) => {
	switch (id) {
		case 'chevron-up':
			return <ChevronUp {...props} />;
		case 'icon-share':
			return <Share {...props} />;
		case 'icon-print':
			return <Print {...props} />;
		case 'chevron-down':
			return <ChevronDown {...props} />;
		case 'DownIcon':
			return <ChevronColoured {...props} />;
		case 'icon-critical':
		case 'critical':
			return <Critical {...props} />;
		case 'info-circle':
		case 'icon-info-circle':
		case 'default':
			return <Info {...props} />;
		case 'close':
			return <Close {...props} />;
		case 'menu':
			return <Menu {...props} />;
		case 'image_grid':
			return <ImageGrid {...props} />;
		case 'arrow-left':
			return <ArrowLeft {...props} />;
		case 'arrow-go':
		case 'arrow-up':
		case 'icon-arrow-go':
			return isDefault ? <ArrowGoDefault {...props} /> : <ArrowGo {...props} />;
		case 'icon-profile':
			return isDefault ? <DefaultProfile {...props} /> : <Profile {...props} />;
		case 'facebook-square':
			return <FacebookSquare {...props} />;
		case 'instagram-square':
			return <InstagramSquare {...props} />;
		case 'youtube-square':
			return <YoutubeSquare {...props} />;
		case 'linkedin-square':
			return <LinkedInSquare {...props} />;
		case 'twitter-square':
			return <TwitterSquare {...props} />;
		case 'warning':
		case 'icon-warning':
			return <Warning {...props} />;
		case 'facebook-round':
			return <FacebookRound {...props} />;
		case 'linkedin-round':
			return <LinkedInRound {...props} />;
		case 'twitter-round':
			return <TwitterRound {...props} />;
		case 'search':
		case '#search':
		case 'icon-search':
		case 'ìcon-search':
			return isColored ? <SearchGreen {...props} /> : <Search {...props} />;
		case 'location':
		case 'icon-location':
			return <Location {...props} />;
		case 'chevron-left-icon':
			return <ChevronLeftIcon {...props} />;
		case 'chevron-right-icon':
			return <ChevronRightIcon {...props} />;
		case 'icon-calendar':
			return <Calender {...props} />;
		case 'icon-expand-fs':
			return <ExpandFS {...props} />;
		case 'icon-play-filled':
			return <PlayFilled {...props} />;
		case 'chevron-next':
			return <ChevronIcon {...props} />;
		case 'map-parking':
			return <MapParking {...props} />;
		default:
			return <></>;
	}
};
export default Icons;
