import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import styled from 'styled-components';

type TableRowContent = {
	id: string;
	fields: {
		heading: Field<string>;
	};
};

type TableItemsContent = {
	id: string;
	fields: {
		items: Array<TableRowContent>;
	};
};

type TableProps = {
	fields: {
		Row: Array<TableRowContent>;
		items: Array<TableItemsContent>;
		Striped: {
			value: boolean;
		};
	};
};

const Table = (props: TableProps): JSX.Element => {
	const { fields } = props;

	const InnovationTable = styled.table`
		border: 1px solid #d6d1cc;
		border-collapse: collapse;
		width: 100%;

		&.non-striped tr:nth-child(even) td {
			background: transparent;
		}
	`;

	const Th = styled.th`
		border-bottom: 1px solid #d6d1cc;
		padding: 15px;
	`;

	const Td = styled.td`
		padding: 15px;
		text-align: center;
	`;

	const Tr = styled.tr`
		&:nth-child(even) td {
			background-color: #f2efeb;
		}
		@media only screen and (max-width: 425px) {
			flex-direction: row;
			display: flex;
			justify-content: space-around;
			margin: 0 !important;
		}
	`;

	const Wrapper = styled.div`
		overflow: auto;
	`;

	return (
		<Wrapper>
			<InnovationTable className={fields?.Striped?.value ? 'non-striped' : ''}>
				<thead>
					<Tr>
						{fields?.Row?.map(({ id, fields }) => (
							<Th key={id}>{fields?.heading?.value || ''}</Th>
						))}
					</Tr>
				</thead>
				<tbody>
					{fields?.items?.map(({ id, fields }) => {
						return (
							<Tr key={id}>
								{fields?.items?.map(({ id, fields }) => (
									<Td key={id}>{fields?.heading?.value || ''}</Td>
								))}
							</Tr>
						);
					})}
				</tbody>
			</InnovationTable>
		</Wrapper>
	);
};

export default Table;
