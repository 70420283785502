import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type AhaPecArticleProps = StyleguideComponentProps & {
	fields: {
		Body: Field<string>;
	};
};

const AhaPecArticle = ({ fields }: AhaPecArticleProps): JSX.Element => {
	if (fields == undefined) {
		return <div>Datasource missing for Article (aha! PEC)</div>;
	}
	const { Body } = fields;

	return <RichText className="aha-pec-article custom-richtext" field={Body} />;
};

export default AhaPecArticle;
