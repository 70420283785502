import React from 'react';
import { Field, LinkField, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Modal from 'assets/shared/Modal';
import { classNames } from 'assets/utils/helper';

type ButtonModalProps = {
	fields: {
		body: Field<string>;
		heading: Field<string>;
		fullScreen: Field<boolean>;
		generalLink: LinkField;
	};
};

const ButtonModal = (props: ButtonModalProps): JSX.Element => {
	const { fields } = props;

	if (!props.fields) {
		return <p>No datasource set</p>;
	}

	return (
		<div>
			<div id="modal-root"></div>
			<Modal
				customClass={classNames('innovation-modal', fields?.fullScreen?.value ? 'full-page' : '')}
				openBtnLabel="Open modal"
				openBtnIcon="play-filled"
				portalId="modal-root"
			>
				<Text tag="h2" className="heading-1" field={fields?.heading} />
				<RichText field={fields?.body} />
				<div className="form-input-wrapper">
					<div className="form-text-field">
						<input id="field-text" type="text" />
						<label className="text-label" htmlFor="field-text">
							Text Field Label
						</label>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ButtonModal;
