import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type LogoWallProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		content: Field<string>;
		logo: ImageField;
		children: Array<ItemValue>;
	};
};

const LogoWall = (props: LogoWallProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="logo-wall jss-inner-container">
			{props.fields.heading && props.fields.content && (
				<ContentBlock title={props.fields.heading} description={props.fields.content} />
			)}

			<div className="logo-wall-grid jss-inner-container">
				{props.fields.children.map((item) => (
					<div key={item.id}>
						<img
							key={item.id}
							src={item.fields.logo.value?.src}
							alt={
								(item.fields.logo.value?.alt as string) != ''
									? (item.fields.logo.value?.alt as string)
									: 'Image'
							}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default LogoWall;
