import React, { useEffect, useState } from 'react';
import ArticleSummary from 'components/organisms/ArticleSummary';
import { useQuery } from '@apollo/client';
import { FilterState, useFilter } from 'components/context/FilterContext';
import { GET_BLOG_POSTS } from './graphql/GET_BLOG_POSTS';
import PaginationControls from 'components/molecules/PaginationControls';
import { useRouter } from 'next/router';

type BlogPostDetails = {
	title: string;
	modifiedDate: string;
	publishedDate: string;
	summary: string;
	categories: string[];
	tags: string[];
	body: string;
	blogImage: string;
	blogLink: string;
};

type ArticleSummaryComponentProps = {
	stackValue: boolean;
};

interface QueryParams {
	tag?: string;
	category?: string;
	author?: string;
}

const populateContextFromQueryParams = (
	queryParams: QueryParams,
	setFilterState: React.Dispatch<React.SetStateAction<any>>
) => {
	const shouldUpdateContext = Object.keys(queryParams).some(
		(key) => queryParams[key as keyof QueryParams] !== undefined
	);
	if (shouldUpdateContext) {
		setFilterState((prevState: FilterState) => ({
			...prevState,
			filter: {
				...prevState.filter,
				...queryParams,
			},
		}));
	}
};

const ArticleSummaryComponent = ({ stackValue }: ArticleSummaryComponentProps): JSX.Element => {
	const { filterState, setFilterState } = useFilter();
	const [index, setIndex] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const { query } = useRouter();
	const filterData = filterState.filter;

	const { loading, error, data } = useQuery(GET_BLOG_POSTS, {
		variables: {
			text: filterData.text,
			page: index,
			pageSize: pageSize,
			filters: {
				category: filterData?.category,
				tag: filterData?.tag,
				author: filterData?.author,
			},
			sortType: 'MOST_RELEVANT',
		},
		context: { clientName: 'searchByISC' },
	});
	useEffect(() => {
		populateContextFromQueryParams(query as unknown as QueryParams, setFilterState);
	}, [query, setFilterState]);

	useEffect(() => {
		if (data && data.blogSearch !== undefined) {
			setFilterState((prevState) => ({
				...prevState,
				total: data.blogSearch.totalSearchResults,
			}));
		}
	}, [data, setFilterState]);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	const articles: BlogPostDetails[] = data.blogSearch.results;
	const totalItems: number = data.blogSearch.totalSearchResults;
	return (
		<div className="mx-0 row">
			{articles.map(
				({ title, summary, blogImage, blogLink, modifiedDate }: BlogPostDetails, index) => {
					return (
						<div key={index} className="col-sm-12 col-12">
							<ArticleSummary
								stacked={stackValue}
								imgSrc={blogImage}
								imgAlt={`articles_image_${index + 1}`}
								date={modifiedDate}
								title={title}
								summary={summary}
								ctaText={'Learn more'}
								ctaHref={blogLink}
							/>
						</div>
					);
				}
			)}
			<PaginationControls
				{...{
					itemsPerPage: pageSize,
					numberOfVisiblePages: data?.globalSearch?.totalPages || 1,
					numberOfItems: totalItems || 1,
					index,
					dataLength: index * pageSize + articles?.length || 0,
					totalResult: totalItems,
					pageSize,
					setPageSize,
					setIndex,
				}}
			/>
		</div>
	);
};

export default ArticleSummaryComponent;
