import React from 'react';
import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type SpecialtiesItem = {
	id: string;
	displayName: string;
};

type ProviderItem = {
	id: string;
	name: string;
	fields: {
		url: string;
		FullName: Field<string>;
		Degrees: Field<string>;
		ExternalId: Field<string>;
		Specialties: Array<SpecialtiesItem>;
		Link: LinkField;
		ProviderImage: ImageField;
		Gender: Field<string>;
	};
};

type FeaturedProvidersProps = {
	fields: {
		Title: Field<string>;
		Providers: Array<ProviderItem>;
		link: LinkField;
	};
};

const FeaturedProviders = (props: FeaturedProvidersProps): JSX.Element => {
	if (props == undefined) {
		return <div>Data source is not defined</div>;
	}

	const { fields } = props;

	if (fields == undefined || fields.Providers == undefined) {
		return <div>Data source is not defined</div>;
	}

	return (
		<div className="featured-list providers">
			<Text className="featured-list-title" tag="h2" field={fields?.Title || ''} />
			<div className="list-inner">
				{fields?.Providers?.map(({ id, fields, name }, index) => {
					return (
						<a
							key={id}
							href={`https://www2.osfhealthcare.org/providers/${String(name)
								?.toLowerCase()
								?.split(' ')
								?.join('-')}-${String(fields?.ExternalId?.value)}`}
							className="featured-item"
						>
							<div className="item-image">
								{fields?.ProviderImage?.value?.src ? (
									<Image alt="image_providers" field={fields?.ProviderImage} />
								) : (
									<img
										alt={`image-${index}`}
										src={`https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-${
											fields.Gender.value === 'Male' ? 'male' : 'female'
										}-provider-transparent`}
									/>
								)}
							</div>
							<div className="content-container" itemScope itemType="http://schema.org/Physician">
								<span className="flex">
									{(fields?.FullName || fields?.Degrees) && (
										<Text
											className="item-name"
											itemProp="name"
											tag="h4"
											field={{
												value: `${fields?.FullName.value || ''},		${fields?.Degrees.value || ''}`,
											}}
										/>
									)}
								</span>
								{fields?.Specialties?.length > 0 &&
									fields?.Specialties.map((specialty: SpecialtiesItem) => (
										<h5
											key={specialty.id}
											className="item-subtitle"
											itemType="https://schema.org/medicalSpecialty"
										>
											{specialty.displayName}
										</h5>
									))}
							</div>
						</a>
					);
				})}
			</div>
			<Link
				className="featured-list-view-all"
				aria-label="View All"
				rel="noreferrer"
				field={fields?.link || { value: '' }}
				onClick={(e) => e?.stopPropagation()}
			/>
		</div>
	);
};

export default FeaturedProviders;
