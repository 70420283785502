import { classNames } from 'assets/utils/helper';
import React from 'react';
import Icons from 'components/atoms/Icons';

type ActionButtonProps = {
	label?: string;
	paragraph?: string;
	link?: string;
	isLinkExternal?: string;
	additionalClasses?: string;
	capacity?: string;
	iconClassName?: string;
	icon?: string;
	rotateIcon?: number;
};

const ActionButton = (props: ActionButtonProps): JSX.Element => (
	<a
		href={props?.link || '#'}
		target={props?.isLinkExternal && '_blank'}
		rel={props?.isLinkExternal && 'noreferrer'}
		className={`action-btn ${props?.additionalClasses || ''}`}
	>
		<div className="action-btn-group">
			<span className="action-text">{props?.label || ''}</span>
			{props?.capacity && <p>Event Capacity: {props?.capacity || ''}</p>}
			{props?.paragraph && <p>{props?.paragraph || ''}</p>}
		</div>
		<span
			className={classNames(
				'icon section-icon',
				props?.icon ? `icon-${props.icon}` : '',
				props?.rotateIcon ? `rotate-svg-${props.rotateIcon}` : ''
			)}
		>
			<Icons id={props?.icon || ''} />
		</span>
	</a>
);

export default ActionButton;
