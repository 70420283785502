import React from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type PullQuoteProps = {
	fields: {
		QuoteDescription: Field<string>;
	};
};
const PullQuote = (props: PullQuoteProps): JSX.Element => {
	const { fields } = props;
	return <RichText className="pull-quote" field={fields?.QuoteDescription} />;
};

export default PullQuote;
