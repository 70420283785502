import React, { useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from '../atoms/Icons';
import { classNames } from 'assets/utils/helper';

type AccordionProps = {
	fields: {
		AccordionHeader: Field<string>;
		AccordionList: Array<AccordionDataContent>;
	};
};

type AccordionDataContent = {
	id: string;
	fields: {
		AccordionContent: Field<string>;
		AccordionTitle: Field<string>;
	};
};

const Accordion = (props: AccordionProps): JSX.Element => {
	const [activeKey, setActiveKey] = useState<string>('');

	const setActiveAccordion = (id: string) => setActiveKey(activeKey === id ? '' : id);

	if (!props.fields) {
		return <p>No datasource set</p>;
	}

	return (
		<div className="jss-inner-container">
			<div className="accordion-container">
				<RichText className="accordion-top-header" tag="div" field={props.fields.AccordionHeader} />
				<div className="accordion">
					{props?.fields &&
						props?.fields?.AccordionList?.map(({ id, fields }, index) => {
							return (
								<div
									key={index}
									className={classNames('accordion-item', id === activeKey ? 'active' : '')}
								>
									<div className="accordion-header" onClick={() => setActiveAccordion(id)}>
										<RichText
											tag="span"
											className="accordion-label"
											field={fields?.AccordionTitle || ''}
										/>
										<Icons id={id === activeKey ? 'chevron-up' : 'chevron-down'} />
									</div>
									<RichText
										tag="div"
										field={fields?.AccordionContent || ''}
										className={classNames('accordion-content')}
									/>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};
export default Accordion;
