import {
	Text,
	Field,
	LinkField,
	ImageField,
	Link,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { useState } from 'react';
import ActionButton from './ActionButton';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type ListingComponentProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		link: LinkField;
		viewalllink: LinkField;
		children: Array<ItemValue>;
	};
};

const ListingComponent = (props: ListingComponentProps): JSX.Element => {
	if (props.fields == undefined) {
		return <div></div>;
	}
	const [limit, setLimit] = useState<number>(9);

	const loadMore = (): void => {
		setLimit(limit + 9);
	};

	return (
		<>
			<div className="jss-Listing-component-headline">
				<ContentBlock title={props.fields.title} />
			</div>
			<div
				className={
					props.fields.children.length === 2 ? 'jss-two-up' : `jss-three-up jss-inner-container`
				}
			>
				{props.fields.children.length > 0 &&
					props.fields.children.slice(0, limit).map((item) => (
						<div key={item.id} className="additional-cards">
							{item.fields.image.value?.src !== undefined && (
								<div className="">
									<img
										src={item.fields.image.value?.src}
										alt={
											(item.fields.image.value?.alt as string) != ''
												? (item.fields.image.value?.alt as string)
												: 'Image'
										}
									/>
								</div>
							)}
							{item.fields.heading.value !== '' && item.fields.content.value !== '' && (
								<div className="content">
									{item.fields.heading.value !== '' && (
										<Text tag="h5" className="header" field={item.fields.heading} />
									)}
									{item.fields.content.value !== '' && (
										<RichText tag="div" className="description" field={item.fields.content} />
									)}
								</div>
							)}

							{item.fields.link.value.href !== '' && (
								<div className="jss-action-button">
									<ActionButton
										label={item.fields.link.value.text}
										link={item.fields.link.value.href}
										isLinkExternal={item.fields.link.value.target}
									/>
								</div>
							)}
							{item.fields.viewalllink.value.href !== '' && (
								<div>
									<Link
										className="text-plus color-green jss-link"
										field={item.fields.viewalllink}
									/>
								</div>
							)}
						</div>
					))}
			</div>
			<div className="jss-inner-container">
				{props.fields.children.length > limit && (
					<div className="osf-load-more">
						<button className="listing-cta innovation-button" onClick={loadMore}>
							Load More
						</button>
					</div>
				)}
			</div>
		</>
	);
};
export default ListingComponent;
